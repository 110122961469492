import React from "react"
import {___} from "../translate"

import {ReactComponent as CarSearch} from "../images/svg/car-search.svg"


const VehicleInfo = ({vehicle, language})=>(
  <div className="vehicle">
    <div className={`regno lang-${language.toLowerCase()}`}><div className="country"><div className="inner"></div></div><div className="text">{vehicle.nr}</div></div>
    <div>{vehicle.manufacturer} </div>
    <div>{vehicle.model} </div>
    <div>{vehicle.name} </div>
    <div>({vehicle.year_start} — {vehicle.year_end})</div>
  </div>
)

const CurrentVehicle = ({currentVehicle, resetVehicle, language})=>{
  return <div className="current-car">
    <h3>{___("Vald bil")}</h3>

    <VehicleInfo vehicle={currentVehicle} language={language}/>
    <div onClick={()=>{resetVehicle()}} className="ml-auto reset-vehicle">
      <span className="btn btn-outline-dark"><CarSearch/>{___("Välj annan bilmodell")}</span>
    </div>

  </div>
}


export default CurrentVehicle
export {
  VehicleInfo
}
