import React from "react"
import {VehicleInfo} from "../components/current-vehicle"

const VehicleSelect = ({vehicles, selectVehicle, language})=>{

  return <div className="select-vehicle">
    {vehicles.map((vehicle, index)=>{
      return <div key={index} onClick={()=>{
        selectVehicle(vehicle)
      }}>
        <VehicleInfo vehicle={vehicle} language={language}/>
        <button className="btn btn-primary btn-lg">Välj och fortsätt</button>
      </div>
    })}
  </div>

}


export default VehicleSelect
